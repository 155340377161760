<template>
  <div class="content">
    <div class="header text-center item-title">{{detail.title}}</div>
    <div class="margin-t-sm">
      <el-card class="box-card">
        <div v-for="pages in detail.pages" :key="pages.id" class="text item annotation-title">
          <el-tag type="info">第{{pages.page}}页</el-tag>
          <div class="text item annotation-content"> 
			 <div class="content annotation-word">
				  <span v-if="pages.type === 1">
					 <el-tag type="success" effect="plain" size="mini">好词</el-tag>
				  </span>
				  <span v-else>
					 <el-tag effect="plain" size="mini">好句</el-tag>
				  </span>
				  <span class="margin-l-sm">{{pages.content}}</span>
			 </div>
			 <div class="content annotation-word margin-t-sm">
				 <el-tag type="danger" effect="plain" size="mini">解析</el-tag>
				 <span class="margin-l-sm">{{pages.analyse}}</span>
			 </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { config } from '../../../js/config'
export default {
  name: "detail",
  data(){
    return {
      id: 0,
      detail: {}
    }
  },
  created() {
    this.id = this.$route.params.id
    this.getDetail()
  },
  methods: {
    ...mapActions('knowledge',['getKnowledge']),
    async getDetail(){
      var vm = this
      const { res_info, data } = await this.getKnowledge({id: this.id})
      if (res_info !== 'ok') return
      vm.detail = data
    }
  }
}
</script>
<style scoped="scoped">
	.box-card {
		background: transparent;
		border: 0.0625rem #909399 solid;
	}
	.annotation-title {
		border-bottom: 0.0625rem #909399 solid;
	}
	.annotation-title:last-child {
		border: none;
	}
	.annotation-content {
		min-height: 12.5rem;
		font: 0.8125rem;
		color: #303133;
		padding-left: 1.25rem;
		margin: 0.03125rem auto;
		border-top: 0.0625rem #666666 dashed;
	}
	.annotation-word  {
		color: #606266;
		font-size: 0.8125rem;
	    padding-top: 0.625rem;
		line-height: 1.875rem;
	}
</style>
